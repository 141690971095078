export const productFeedManager = {
  app: {
    name: '商品カタログカスタマイズ',
  },
  tableHeader: {
    channelName: '商品カタログ名',
    lang: '言語',
    updateTime: '最終更新日',
  },
  emptyList: {
    title: '商品カタログ内容をカスタマイズして、手軽に管理！',
    description: 'ここで商品カタログのルールを追加、管理します',
  },
  title: {
    channelEntry: '商品カタログを追加',
  },
  tips: {
    chanelLimit: '上限に達しました',
  },
  tooltip: {
    copyLink: 'リンクをコピー',
  },
  popover: {
    productFeedManagerFaq: {
      title: '詳しくはこちら',
      content:
        '各チャネルの商品カタログカスタマイズは更新時間が異なります。詳しくはFAQをご覧ください。',
      function: '機能説明',
      link: 'https://support.shoplineapp.com/hc/en-us/articles/35139419848217',
    },
  },
  channel: {
    title_GOOGLE: 'Google',
    title_CRITEO: 'Criteo',
  },
  channelListPopup: {
    title: 'パイプを選択してください',
    description:
      '現在、各チャネルにはカスタマイズ商品カタログを 1 つのみ追加できます。追加するチャネルを選択してください',
    description_GOOGLE:
      'Pinterest および TikTok 商品カタログ、また一部の Martech およびショッピングガイドチャネル (Omnichat、Influenxio、Insider、Heduo、Rosetta など) をサポートしています。',
    description_CRITEO: 'リマーケティングのための最も強力なツール',
  },
  form: {
    title: '商品アップロードルール',
    description: 'さまざまな条件を使用して商品カタログの生成ルールを作成',
    valid: {
      required: '{{fieldName}} は必須です',
    },
    fields: {
      title: {
        title: '商品カタログ名',
        placeholder: '最大50文字まで入力可能',
      },
      withOutOfStockProduct: {
        title: '商品販売ステータスを選択',
        options: {
          all: '在庫の有無に関係なく商品をアップロード',
          part: '在庫ありまたは販売可能庵商品のみアップロード',
        },
      },
      withPreorderProduct: {
        title: '予約商品を追加',
      },
      categoryIds: {
        title: '指定商品カテゴリ（オプション）',
        placeholder: '選択してください',
        selectedNum: '（{{num}}）点選択済み',
      },
      uniqueIds: {
        title: 'システムに依存しない製品番号または品目番号を使用しますか?',
        options: {
          number: '製品番号を使用する',
          customNumber: 'システムに依存しない製品番号を使用する',
        },
      },
      mainProductImageSelect: {
        title: '商品のメイン画像を選択',
        options: {
          first: '１ページ目',
          second: '２ページ目',
          last: '最後のページ',
        },
      },
      variantProductImageSelect: {
        title: '商品仕様画像データを選択',
        options: {
          main: '商品仕様画像データを選択',
          sku: '商品仕様画像',
        },
      },
      withMpn: {
        title: '商品番号 (MPN)および、商品バーコード (Barcode)を追加しますか?',
      },
      locale: {
        title: 'ターゲット言語を選択',
        options: {
          zhHant: '繁体字中国語',
          zhHans: '簡体字中国語',
          en: 'English',
        },
      },
    },
    footer: {
      save: '保存',
      description:
        '最後のステップです！上記の内容を確認しクリックして保存してください',
    },
    leavePopup: {
      title: '変更は保存されていません',
      description:
        '終了すると変更は失われます。終了前に保存することをお勧めします。',
    },
  },
  productOverview: {
    title: '商品ビュー',
    description:
      '左側の条件を満たす場合、アップロードされる商品情報を表示します。これはカスタマイズされた商品カタログの次回の更新に含まれる商品のみをリストしており、カタログのリアルタイムデータを表すものではありません',
    entry: '商品を確認',
    popup: {
      title: '商品を確認',
      footer: {
        total: ' {{n}} 点の商品を含む',
      },
    },
    table: {
      column: {
        name: '製品名',
        variation: '商品仕様',
      },
    },
    empty: {
      title: '一致しない商品',
      description: 'もう一度商品アップロードルールを調整してください',
    },
  },
  deletePopup: {
    title: '商品カタログを削除',
    description:
      '削除すると、この商品カタログに使用している広告に影響が出る可能性があります。 商品はメディアチャネルから削除されます。',
    confirmDescription: '影響があることを理解した上で商品カタログを削除します',
  },
};

export default productFeedManager;
