export const common = {
  Test: 'テスト',
  Save: 'ストア',
  Add: '追加',
  Edit: '編集',
  View: 'レビュー',
  Return: '戻る',
  Cancel: 'やめる',
  Cancel2: 'キャンセル',
  Cancel_return: '$t(Return)',
  Remove: '削除',
  Contact: 'カスタマーセンターに連絡',
  Delete: '削除',
  Leave: '離れる',
  Confirm: '確認する',
};
