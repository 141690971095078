export const auth = {
  page: {
    unAuthorized: {
      title: '申し訳ありませんが、このページは現在公開されていません。',
      description:
        'それでもこのページに入る必要がある場合は、ストアマネージャーに連絡して許可をリクエストしてください。',
    },
  },
};

export default auth;
